<template>
  <div>
    <el-dialog :title="text"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="600px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               ref="formRef"
               label-width="110px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="学校名称"
                          prop="school_name">
              <el-input placeholder="请输入学校名称"
                        v-model="form.school_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="描述">
              <el-input placeholder="请输入描述"
                        v-model="form.school_desc"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="教育局">
              <el-select v-model="form.bureau_id"
                         filterable
                         clearable
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in options"
                           :key="item.bureau_id"
                           :label="item.bureau_name"
                           :value="item.bureau_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="学校使用中"
                          class="hidden_label">
              <el-radio v-model="form.is_enabled"
                        @click.prevent.native="checkRadio('is_enabled')"
                        :label="1"></el-radio>
            </el-form-item>
          </el-col>

          <el-col :span="24"
                  v-if="form.school_id != 1">
            <el-form-item label="本校改卷"
                          class="hidden_label">
              <el-radio v-model="form.is_checked_only_by_local"
                        @click.prevent.native="checkRadio('is_checked_only_by_local')"
                        :label="1"></el-radio>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="超时释放"
                          class="hidden_label">
              <el-radio v-model="form.is_checked_by_local_and_shanli"
                        @click.prevent.native="checkRadio('is_checked_by_local_and_shanli')"
                        :label="1"></el-radio>
            </el-form-item>
            <div class="attention">
              注:超4小时释放
            </div>
          </el-col>

          <el-col :span="24">
            <el-form-item label="是否企业"
                          class="hidden_label">
              <el-radio v-model="form.is_retail"
                        @click.prevent.native="checkRadio('is_retail')"
                        :label="0"></el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否显示校本"
                          class="hidden_label">
              <el-radio v-model="form.edu_file"
                        @click.prevent.native="checkRadio('edu_file')"
                        :label="1"></el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="答疑平台">
              <!--  @click.prevent.native="checkRadio('discuss')" -->
              <el-radio v-model="form.is_self_discuss"
                        :label="0">平台</el-radio>
              <el-radio v-model="form.is_self_discuss"
                        :label="1">本校</el-radio>
            </el-form-item>
          </el-col>

        </el-row>

      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      schoolId: '',
      text: '',
      options: [],
      rules: {
        school_name: [
          { required: true, message: '请输入', trigger: 'blur' },
        ]
      },
    }
  },
  mounted () {
    this.getBureauList()
  },
  methods: {
    getBureauList () {
      this.$http({
        url: '/api/v2/bureau/lst',
        method: 'get',
        params: {
          page: 1,
          limit: 10000
        }
      }).then(res => {
        this.options = res.data.list
      })
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    save () {

      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.bureau_id = form.bureau_id ? form.bureau_id : ''
        this.$http({
          url: '/api/v2/bureau/school_create',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '修改成功!',
            type: 'success'
          });
          this.$parent.refresh()
          this.handleClose()
        })
      })
    },
    checkRadio (str) {
      if (this.form[str] == 1) {
        this.form[str] = 0
      } else {
        this.form[str] = 1
      }
      this.$forceUpdate()
      // if (str == 'is_checked_by_local_and_shanli' && this.form[str] == 1) {
      //   this.form.is_checked_only_by_local = 0
      // }

      // if (str == 'is_checked_only_by_local' && this.form[str] == 1) {
      //   this.form.is_checked_by_local_and_shanli = 0
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}

.hidden_label {
  ::v-deep .el-radio__label {
    display: none;
  }
}
.attention {
  position: relative;
  color: red;
  margin-left: 6em;
  top: -30px;
  font-size: 12px;
}
</style>