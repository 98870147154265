<template>
  <div>
    <el-row :gutter="10"
            style="padding-bottom:10px">
      <el-col :span="6">
        <el-input clearable
                  style="width:100%"
                  v-model="keyword"
                  placeholder="请输入学校名称"
                  @keyup.enter.native="search"></el-input>
      </el-col>
      <el-col :span="12">
        <el-button type="primary"
                   @click="search">确定</el-button>
        <el-button type="primary"
                   @click="addSchool">新增</el-button>
      </el-col>
    </el-row>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
export default {
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      keyword: '',
      tableData: [],
      tableLoading: false,
      showList: [],
      pageName: 'schoolAdmin',
      tableColumns: [
        { prop: 'school_id', align: 'center', label: '编号', width: '80', showToopic: false },
        { prop: 'bureau_name', align: 'center', label: '所属教育局', width: '', showToopic: false },
        { prop: 'school_name', align: 'center', label: '名称', width: '', showToopic: true },
        { prop: 'school_desc', align: 'center', label: '描述', width: '', showToopic: false },
        { prop: 'is_enabled', align: 'center', label: '是否在用', width: '', showToopic: false, isStatus: true },
        { prop: 'is_checked_only_by_local', align: 'center', label: '完全由本校改卷', width: '', showToopic: false, isStatus: true },
        { prop: 'is_checked_by_local_and_shanli', align: 'center', label: '本校改卷，超时释放', width: '', showToopic: false, isStatus: true },
        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'showForm' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ]
    }
  },
  components: {
    Form
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    getClass (prop, value) {
      // 根据不同字段做处理
      if (prop == 'is_checked_only_by_local') {
        return value ? 'pass' : 'notpass'
      }
      if (prop == 'is_checked_by_local_and_shanli') {
        return value ? 'pass' : 'notpass'
      }
      if (prop == 'is_enabled') {
        return value ? 'pass' : 'notpass'
      }
    },
    getValue (prop, value) {
      if (prop == 'is_checked_only_by_local') {
        return value ? '是' : '否'
      }
      if (prop == 'is_checked_by_local_and_shanli') {
        return value ? '是' : '否'
      }
      if (prop == 'is_enabled') {
        return value ? '是' : '否'
      }
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      this.tableLoading = true
      this.$http({
        url: '/api/v2/bureau/school_lst',
        method: 'get',
        params: {
          page,
          limit,
          keyword: this.keyword
        }
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
        this.tableLoading = false
      }).catch(() => {
        this.tableLoading = false
      })
    },

    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v2/bureau/school_del',
          method: 'post',
          data: {
            school_id: row.school_id
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        setTimeout(() => {
          this.refresh()
        }, 500);
      })
    },
    addSchool () {
      let form = {
        school_name: '',
        school_desc: '',
        bureau_id: '',
        is_enabled: 0,
        is_checked_by_local_and_shanli: 0,
        is_checked_only_by_local: 0,
        is_retail: 0,
        edu_file: 0,
        is_self_discuss: 0,
      }
      this.$refs.FormRef.form = form
      this.$refs.FormRef.text = '新增学校'
      this.$refs.FormRef.dialogVisible = true
    },
    showForm (row) {
      let form = JSON.parse(JSON.stringify(row))
      // this.$refs.FormRef.schoolId = row.school_id
      this.$refs.FormRef.text = '修改学校'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
